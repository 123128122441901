<template>
	<Dialog :dialog="addressDialog" :dialogWidth="800">
		<template v-slot:title>{{ addressTitle }}</template>
		<template v-slot:body>
			<div class="min-height-280">
				<v-form
					v-if="addressDialog"
					ref="addressForm"
					v-model.trim="personFormValid"
					lazy-validation
					v-on:submit.stop.prevent="updateBillingAddress"
				>
					<v-row>
						<v-col md="12">
							<v-row>
								<v-col md="3" class="my-auto py-0">
									<label for="loacation" class="btx-label mt-2 required">Display Name</label>
								</v-col>
								<v-col md="9" class="py-0">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="location"
										placeholder="Display Name"
										v-model="addressModel.location"
										:rules="[vrules.required(addressModel.location, 'Location')]"
										:class="{ required: !addressModel.location }"
									></TextInput>
								</v-col>
								<v-col md="3" class="my-auto py-0">
									<label for="postal-code" class="btx-label mt-2 text-right required">Postal Code </label>
								</v-col>
								<v-col md="9" class="py-0">
									<v-text-field
										outlined
										class="mt-3"
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="postal-code"
										placeholder="Postal Code"
										type="number"
										v-mask="'######'"
										v-model="addressModel.address_postal_code"
										:rules="[
											vrules.required(addressModel.address_postal_code, 'address postal code'),
											vrules.phoneNumber(addressModel.address_postal_code, 'address postal code', 6),
										]"
										:class="{
											required: !addressModel.address_postal_code,
										}"
									></v-text-field>
								</v-col>
								<v-col md="3" class="my-auto py-0">
									<label for="address-line-1" class="btx-label mt-2 required">Address Line 1 </label>
								</v-col>
								<v-col md="9" class="py-0">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="address-line-1"
										placeholder="Address Line 1"
										:rules="[vrules.required(addressModel.address_line_1, 'Address line 1')]"
										v-model="addressModel.address_line_1"
									></TextInput>
								</v-col>
								<v-col md="3" class="my-auto py-0">
									<label for="address-line-2" class="btx-label mt-2">Address Line 2 </label>
								</v-col>
								<v-col md="9" class="py-0">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="address-line-2"
										placeholder="Address Line 2"
										v-model="addressModel.address_line_2"
									></TextInput>
								</v-col>
								<v-col md="3" class="my-auto py-0">
									<label for="unit-number" class="btx-label mt-2">Unit No. </label>
								</v-col>
								<v-col md="9" class="py-0">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="unit-number"
										placeholder="Unit No."
										v-model="addressModel.unit_number"
									></TextInput>
								</v-col>
								<v-col md="3" class="my-auto py-0">
									<label for="address-country" class="btx-label mt-2">Country </label>
								</v-col>
								<v-col md="9" class="py-0">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="address-country"
										placeholder="Country"
										v-model="addressModel.address_country"
									></TextInput>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-form>
				<template v-if="false && manageDesignationDialog">
					<ManageDesignation
						:dialog="manageDesignationDialog"
						:list="designationList"
						v-on:close="manageDesignationDialog = false"
						v-on:success="getOption('designation')"
					></ManageDesignation>
				</template>
			</div>
		</template>
		<template v-slot:action>
			<v-btn depressed tile :disabled="addressFormLoading" v-on:click="$emit('close', true)">
				Close
			</v-btn>
			<v-btn
				class="white--text"
				depressed
				:loading="addressFormLoading"
				:disabled="!personFormValid || addressFormLoading"
				color="blue darken-4"
				@click="updateBillingAddress()"
				tile
			>
				Save
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
// import EmailInput from "@/view/components/EmailInput";
// import SelectInput from "@/view/components/SelectInput";
import ManageDesignation from "@/view/components/Manage-Designation.vue";
// import AutoCompleteInput from "@/view/components/AutoCompleteInput";
// import PhoneInput from "@/view/components/Phone";
import { UpdateSupplierAddress } from "@/core/lib/contact.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_LOCAL_DB } from "@/core/services/store/config.module";

export default {
	name: "create-update-person",
	title: "Create Update Person",
	props: {
		addressDialog: {
			type: Boolean,
			default: false,
		},
		contactUuid: {
			type: String,
			default: null,
		},
		address: {
			type: Object,
			default: null,
		},
		type: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			personFormValid: true,
			addressFormLoading: false,
			manageDesignationDialog: false,
			pageLoading: false,
			designationList: [],
			addressTitle: "Update Address",
			addressModel: {
				id: null,
				uuid: null,
				name: null,
				location: null,
				address_line_1: null,
				address_line_2: null,
				unit_number: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				address_type: 1,
			},
		};
	},
	watch: {
		address: {
			deep: true,
			handler() {
				this.updateAddressDialog();
			},
		},
		addressDialog(param) {
			if (param) {
				this.updateAddressDialog();
			}
		},
	},
	methods: {
		getOption() {
			this.$store.dispatch(SET_LOCAL_DB).then(() => {
				this.initialize();
			});
		},
		initialize() {
			this.designationList = this.localDB("designation", []);
		},
		updateAddressDialog() {
			if (this.address && !isEmpty(this.address)) {
				this.addressTitle = "Update Address";
				this.addressModel = new Object({
					id: this.address.id || null,
					uuid: this.address.uuid || null,
					name: this.address.name || null,
					location: this.address.location || null,
					address_line_1: this.address.address_line_1 || null,
					address_line_2: this.address.address_line_2 || null,
					unit_number: this.address.unit_number || null,
					address_country: this.address.address_country || null,
					address_postal_code: this.address.address_postal_code || null,
					description: this.address.description || null,
					address_type: 1,
				});
			} else {
				this.addressTitle = "Create Address";
				this.addressModel = new Object({
					id: null,
					uuid: null,
					name: null,
					location: null,
					address_line_1: null,
					address_line_2: null,
					unit_number: null,
					address_country: "Singapore",
					address_postal_code: null,
					description: null,
					address_type: 1,
				});
			}
		},
		async updateBillingAddress() {
			if (!this.$refs.addressForm.validate()) {
				return false;
			}
			try {
				this.addressFormLoading = true;
				await UpdateSupplierAddress(this.type, this.contactUuid, {
					address: [this.addressModel],
				});
				this.$emit("success", true);
				this.$emit("close", true);
				this.$store.commit(SET_MESSAGE, [
					{
						model: true,
						message: "Success ! Address information has been saved.",
					},
				]);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				this.$emit("error", true);
			} finally {
				this.addressFormLoading = false;
			}
		},
	},
	components: {
		Dialog,
		TextInput,
		// EmailInput,
		// SelectInput,
		// PhoneInput,
		ManageDesignation,
		// AutoCompleteInput,
	},
	mounted() {
		this.updateAddressDialog();
		this.initialize();
	},
	computed: {
		...mapGetters(["localDB"]),
	},
};
</script>
