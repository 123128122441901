<template>
	<v-sheet class="vendor" style="height: calc(100vh - 88px)">
		<template v-if="contentLoaded">
			<v-layout class="align-center light-blue-bg px-4 py-2">
				<v-flex md12>
					<div class="d-flex align-items-center">
						<p class="my-auto font-level-3-bold" style="font-size: 20px">
							<ShowValue :object="supplier" object-key="company_name" label="company name"></ShowValue>
						</p>
						<ShowBarcode
							:object="supplier"
							object-key="barcode"
							tooltip
							tooltip-text="supplier #"
							click-to-copy
							label="barcode"
						></ShowBarcode>
						<v-spacer></v-spacer>
						<v-btn
							v-if="false && getPermission('supplier:create')"
							v-on:click="doAction('clone')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-content-duplicate</v-icon>Clone</v-btn
						>
						<v-btn
							v-on:click="doAction('edit')"
							color="blue darken-4 text-white"
							class="ml-2"
							depressed
							v-if="getPermission('supplier::edit')"
							tile
							><v-icon small left>mdi-pencil</v-icon>Edit</v-btn
						>
						<v-btn
							v-on:click="backToListing('supplier')"
							color="grey lighten-3"
							class="ml-2"
							depressed
							tile
							><v-icon small left>mdi-arrow-left</v-icon>Back</v-btn
						>
						<template>
							<v-btn
								v-if="false && vendorTab == 'address-book'"
								v-on:click="doAction('new-address')"
								color="blue darken-4 text-white"
								class="ml-2"
								depressed
								tile
								><v-icon small left>mdi-playlist-plus</v-icon>Add New Address</v-btn
							>
						</template>
					</div>
					<div class="d-flex mt-4">
						<div class="w-10 pr-3">
							<ImageTemplate circle :src="supplier.image"></ImageTemplate>
						</div>
						<div class="w-100 pr-3">
							<div class="d-flex flex-wrap mt-2">
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Display Name : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="supplier" object-key="display_name" label="display name"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Category : </span>
										<span class="fw-500">
											<ShowValue
												:object="category"
												object-key="category_name"
												label="category name"
											></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">Company Phone no : </span>
										<span class="fw-500 no-wrap">
											<ShowValue :object="supplier" object-key="phone" label="company phone no"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Company Email : </span>
										<span class="fw-500">
											<ShowValue :object="supplier" object-key="email" label="company email"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600">UEN : </span>
										<span class="fw-500">
											<ShowValue :object="supplier" object-key="uen" label="uen"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Created date : </span>
										<span class="fw-500" v-if="supplier.added_at">
											{{ formatDateTime(supplier.added_at) }}
										</span>
										<em v-else class="text-muted">no created date</em>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Created By : </span>
										<span class="fw-500">
											<ShowValue :object="supplier" object-key="added_by" label="created by"></ShowValue>
										</span>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Updated date : </span>
										<span class="fw-500" v-if="supplier.updated_at">
											{{ formatDateTime(supplier.updated_at) }}
										</span>
										<em v-else class="text-muted">no updated date</em>
									</div>
								</div>
								<div class="w-33">
									<div class="mt-2">
										<span class="fw-600 mr-2">Updated By : </span>
										<span class="fw-500">
											<ShowValue :object="supplier" object-key="updated_by" label="updated by"></ShowValue>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</v-flex>
			</v-layout>
			<div>
				<v-tabs
					v-model="vendorTab"
					background-color="transparent"
					color="blue"
					class="custom-tab-transparent"
					active-class="blue darken-4 text-white"
					hide-slider
				>
					<v-tab href="#overview"> Overview </v-tab>
					<v-tab href="#contact-persons">
						Contact Persons ({{ this.contact_person && this.contact_person.length }})</v-tab
					>
					<v-tab href="#attachments">
						<v-icon small left>mdi-file-document-multiple</v-icon> Attachments</v-tab
					>
					<v-tab href="#product"> <v-icon small left>mdi-package-variant-closed</v-icon> Products</v-tab>
					<v-tab href="#event"> Logs </v-tab>
					<v-tabs-items v-model="vendorTab">
						<v-tab-item value="overview">
							<v-row>
								<v-col md="12" class="bg-light-grey">
									<div class="mt-3 d-flex px-4">
										<span class="fw-600 no-wrap mr-1">Business description : </span>
										<span class="fw-500 break-spaces">
											<ShowTextAreaValue
												:object="supplier"
												object-key="business_description"
												label="business description"
											></ShowTextAreaValue>
										</span>
									</div>
									<v-row>
										<v-col md="6" lg="6">
											<v-expansion-panels
												flat
												accordion
												tile
												class="mt-1 transparent-panels"
												v-model="panel12"
												multiple
											>
												<v-expansion-panel>
													<v-expansion-panel-header>
														<v-layout>
															<v-flex md10>Address</v-flex>
															<v-flex md2 class="text-right">
																<v-icon
																	v-on:click.stop.prevent="addressDialog = true"
																	dense
																	color="blue darken-4"
																	class="mx-2"
																	>mdi-pencil-circle-outline</v-icon
																>
															</v-flex>
														</v-layout>
													</v-expansion-panel-header>
													<v-expansion-panel-content>
														<v-layout>
															<v-flex>
																<v-chip v-if="false" label class="blue lighten-4 blue--text text--darken-4">
																	Billing Address
																</v-chip>
																<div class="d-flex align-items-start mt-0">
																	<v-icon class="pr-1" color="blue darken-4">mdi-map-marker-outline</v-icon>
																	<div>
																		<p class="m-0" v-if="billing_address && billing_address.location">
																			<ShowValue
																				:object="billing_address"
																				object-key="location"
																				label="location"
																			></ShowValue>
																		</p>
																		<p class="m-0" v-if="billing_address && billing_address.address_line_1">
																			<ShowValue
																				:object="billing_address"
																				object-key="address_line_1"
																				label="address line 1"
																			></ShowValue>
																		</p>
																		<p class="m-0" v-if="billing_address && billing_address.address_line_2">
																			<ShowValue
																				:object="billing_address"
																				object-key="address_line_2"
																				label="address line 2"
																			></ShowValue>
																		</p>
																		<p class="m-0" v-if="billing_address && billing_address.unit_number">
																			<ShowValue
																				:object="billing_address"
																				object-key="unit_number"
																				label="unit number"
																			></ShowValue>
																		</p>
																		<p class="m-0">
																			<template v-if="billing_address && billing_address.address_country">
																				<ShowValue
																					:object="billing_address"
																					object-key="address_country"
																					label="country"
																				></ShowValue>
																			</template>
																			<template v-if="billing_address && billing_address.address_postal_code">
																				&nbsp;
																				<ShowValue
																					:object="billing_address"
																					object-key="address_postal_code"
																					label="postal code"
																				></ShowValue>
																			</template>
																		</p>
																	</div>
																</div>
															</v-flex>
															<v-flex v-if="false" class="my-auto text-right">
																<v-btn icon small color="blue darken-4" v-on:click="updateAddressDialog('billing')">
																	<v-icon small>mdi-pencil</v-icon>
																</v-btn>
															</v-flex>
														</v-layout>
														<v-layout class="mt-6" v-if="false && vendor && vendor.shipping_address">
															<v-flex>
																<p class="mb-1 bold-500">Shipping Address</p>
																<p class="m-0">
																	<span class="bold-600">{{ vendor.shipping_address.name }}</span>
																	{{ addressFormat(vendor.shipping_address) }}
																</p>
															</v-flex>
															<v-flex class="my-auto text-right">
																<v-btn
																	v-if="false"
																	icon
																	small
																	color="blue darken-4"
																	v-on:click="updateAddressDialog('shipping')"
																>
																	<v-icon small>mdi-pencil</v-icon>
																</v-btn>
															</v-flex>
														</v-layout>
													</v-expansion-panel-content>
												</v-expansion-panel>
												<v-expansion-panel v-if="false">
													<v-expansion-panel-header>Other Details</v-expansion-panel-header>
													<v-expansion-panel-content>
														<v-layout class="mb-1">
															<v-flex md5> <span class="bold-600">Supplier ID : </span></v-flex>
															<v-flex md7>{{ supplier.barcode }}</v-flex>
														</v-layout>

														<v-layout class="mb-1">
															<v-flex md5><span class="bold-600">Company Name </span></v-flex>
															<v-flex md7 class="text-truncate">
																{{ supplier.company_name }}
																<TextInputInternal
																	v-if="false"
																	field="company_name"
																	:uuid="uuid"
																	endpoint="supplier"
																	v-model="supplier.company_name"
																	v-on:success="getVendor()"
																>
																</TextInputInternal>
															</v-flex>
														</v-layout>

														<v-layout class="mb-1">
															<v-flex md5><span class="bold-600">Display Name </span></v-flex>
															<v-flex md7 class="text-truncate">
																{{ supplier.display_name }}
																<TextInputInternal
																	v-if="false"
																	field="display_name"
																	:uuid="uuid"
																	endpoint="supplier"
																	v-model="supplier.display_name"
																	v-on:success="getVendor()"
																>
																</TextInputInternal>
															</v-flex>
														</v-layout>

														<v-layout class="mb-1">
															<v-flex md5><span class="bold-600">Phone Number</span></v-flex>
															<v-flex md7 class="text-truncate"
																>{{ supplier.phone }}
																<PhoneInputInternal
																	v-if="false"
																	field="fax"
																	:uuid="uuid"
																	endpoint="contact/supplier"
																	v-model="supplier.phone"
																	v-on:success="getVendor()"
																></PhoneInputInternal>
															</v-flex>
														</v-layout>

														<v-layout>
															<v-flex md5><span class="bold-600">UEN</span></v-flex>
															<v-flex md7 class="text-truncate"
																>{{ supplier.uen }}
																<TextInputInternal
																	v-if="false"
																	field="uen"
																	:uuid="uuid"
																	endpoint="supplier"
																	v-model="supplier.uen"
																	v-on:success="getVendor()"
																></TextInputInternal>
															</v-flex>
														</v-layout>
														<v-layout>
															<v-flex md5><span class="bold-600">Category</span></v-flex>
															<v-flex md7 class=""
																>{{ category && category.category_name ? category.category_name : "-" }}
															</v-flex>
														</v-layout>

														<v-layout>
															<v-flex md5><span class="bold-600">Email</span></v-flex>
															<v-flex md7 class="text-truncate"
																>{{ supplier.email }}
																<TextInputInternal
																	v-if="false"
																	field="website"
																	:uuid="uuid"
																	endpoint="supplier"
																	v-model="supplier.email"
																	v-on:success="getVendor()"
																></TextInputInternal>
															</v-flex>
														</v-layout>

														<v-layout>
															<v-flex md5><span class="bold-600">Business decription</span></v-flex>
															<v-flex md7 class="">{{ supplier.business_description }} </v-flex>
														</v-layout>
													</v-expansion-panel-content>
												</v-expansion-panel>
											</v-expansion-panels>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-tab-item>
						<v-tab-item value="contact-persons">
							<template v-if="contact_person.length">
								<v-layout class="border-bottom-light-grey pa-2">
									<v-flex class="font-level-3-bold my-auto">
										<span class="detail-svg-icon mr-2">
											<!--begin::Svg Icon-->
											<inline-svg :src="$assetURL('media/custom-svg/member-color.svg')" />
											<!--end::Svg Icon-->
										</span>
										<template>Contact Persons</template>
									</v-flex>
									<v-flex class="text-right">
										<v-btn color="blue darken-4 text-white" class="ml-2" depressed tile @click="addPerson()"
											><v-icon small left>mdi-plus</v-icon>Add Contact Person</v-btn
										>
									</v-flex>
								</v-layout>
								<v-layout class="flex-wrap">
									<v-flex v-for="(person, index) in contact_person" :key="index" md4 class="pa-2">
										<div class="border pa-3 h-100">
											<div>
												<p class="text-truncate mb-1 bold-600 text-capitalize">
													{{ person.full_name }}
													<v-chip class="mx-2" label x-small color="green" v-if="person.primary == 1">
														<span class="white--text">Primary</span>
													</v-chip>
												</p>
												<v-menu left tile offset-x>
													<template v-slot:activator="{ on, attrs }">
														<v-btn
															icon
															small
															color="blue darken-4"
															class="btx-icon-top-right"
															v-bind="attrs"
															v-on="on"
														>
															<v-icon small>mdi-cog</v-icon>
														</v-btn>
													</template>

													<v-list small>
														<v-list-item link v-on:click="addPerson(person)">
															<v-list-item-title>
																<v-icon small left color="blue darken-4">mdi-pencil</v-icon>
																Edit</v-list-item-title
															>
														</v-list-item>
														<v-list-item link v-if="!person.primary" v-on:click="markAsPrimary(person.uuid)">
															<v-list-item-title>
																<v-icon small left color="blue darken-4">mdi-check-bold</v-icon>
																Mark as Primary</v-list-item-title
															>
														</v-list-item>
														<template v-if="contact_person.length > 1">
															<v-list-item
																link
																v-if="!person.primary"
																v-on:click="deletePerson(person.uuid)"
																class="red--text text--lighten-1"
															>
																<v-list-item-title class="red--text text--lighten-1">
																	<v-icon small left color="red lighten-1">mdi-delete</v-icon>
																	Delete</v-list-item-title
																>
															</v-list-item>
														</template>
													</v-list>
												</v-menu>
											</div>
											<p class="mb-1" v-if="person.display_name">
												<v-icon left small>mdi-account</v-icon> {{ person.display_name }}
											</p>
											<p class="mb-1 text-lowercase" v-if="person.email">
												<v-icon left small>mdi-email</v-icon>{{ person.email }}
											</p>
											<p class="mb-1 text-lowercase" v-if="person.phone">
												<v-icon left small>mdi-phone</v-icon>{{ person.phone }}
											</p>
											<p class="mb-1 text-lowercase" v-if="person.did">
												<v-icon left small>mdi-phone</v-icon>{{ person.did }}
												<span class="text-uppercase fw-600 blue--text text--darken-4">(did)</span>
											</p>
											<p class="mb-1" v-if="person.designation">
												<v-icon left small>mdi-briefcase</v-icon> {{ person.designation }}
											</p>
										</div>
									</v-flex>
								</v-layout>
							</template>
						</v-tab-item>
						<v-tab-item value="attachments">
							<Files
								v-if="vendorTab == 'attachments'"
								class="mx-4"
								type-text="Supplier Attachments"
								type="supplier"
								:type-uuid="uuid"
							></Files>
						</v-tab-item>
						<v-tab-item value="event">
							<EventListing
								v-if="vendorTab == 'event' && uuid"
								class="mx-4"
								type-text="Supplier"
								type="supplier"
								:type-uuid="uuid"
							></EventListing>
						</v-tab-item>
						<v-tab-item value="product">
							<SupplierProductListing />
						</v-tab-item>
						<v-tab-item v-if="false" value="comment">
							<Comments
								v-if="vendorTab == 'comment' && uuid"
								class="mx-4"
								type-text="Vendor"
								type="vendor"
								:type-uuid="uuid"
							></Comments>
						</v-tab-item>
						<v-tab-item v-if="false" value="address-book">
							<SupplierAddressBook
								v-if="false && vendorTab == 'address-book'"
								type-text="Supplier"
								type="supplier/contact"
								:new-address="addressDialog"
								:type-uuid="uuid"
								v-on:close="addressDialog = false"
							>
							</SupplierAddressBook>
						</v-tab-item>
						<v-tab-item v-if="false" value="asset">
							<AssetListing
								v-if="vendor && vendor.id && vendorTab == 'asset'"
								class="mx-4"
								relation-type="supplier"
								:relation-type-id="vendor.id"
							></AssetListing>
						</v-tab-item>
						<v-tab-item v-if="false" value="asset-stock">
							<AssetStockListing
								v-if="vendor && vendor.id && vendorTab == 'asset-stock'"
								class="mx-4"
								relation-type="supplier"
								:relation-type-id="vendor.id"
							></AssetStockListing>
						</v-tab-item>
						<v-tab-item v-if="false" value="inventory">
							<InventoryListing
								v-if="vendor && vendor.id && vendorTab == 'inventory'"
								class="mx-4"
								relation-type="supplier"
								:relation-type-id="vendor.id"
							></InventoryListing>
						</v-tab-item>
						<v-tab-item v-if="false" value="services">
							<template v-if="uuid">
								<ServiceListing
									v-if="vendorTab == 'services'"
									class="mx-4"
									:uuid="uuid"
									type="supplier"
								></ServiceListing>
							</template>
						</v-tab-item>
						<v-tab-item v-if="false" value="mail"> // mails </v-tab-item>
					</v-tabs-items>
				</v-tabs>
				<CreateOrUpdatePerson
					v-if="personDialog && supplier.id"
					type="supplier"
					:person-dialog="personDialog"
					:vendor-uuid="uuid"
					:person="personModel"
					:contactUuid="supplier.uuid"
					:contact-id="supplier.id"
					v-on:close="personDialog = false"
					v-on:success="getVendor"
				>
				</CreateOrUpdatePerson>
				<CreateOrUpdateBillingAddress
					type="supplier/contact"
					:address-dialog="addressDialog"
					:vendor-uuid="uuid"
					:address="addressModel"
					:contactUuid="uuid"
					v-on:close="addressDialog = false"
					v-on:success="getVendor"
				>
				</CreateOrUpdateBillingAddress>
				<template v-if="mail_dialog && vendor && vendor.id">
					<SendMail
						:mail-dialog="mail_dialog"
						title="Send as Email"
						type="contact"
						v-on:close="mail_dialog = false"
						:type-id="vendor.id"
						v-on:success="getVendor()"
					></SendMail>
				</template>
			</div>
			<DeleteTemplate
				type="Supplier"
				:delete-text="deleteText"
				v-on:success="goBack()"
				v-on:close="deleteDialog = false"
				:delete-dialog="deleteDialog"
				:delete-url="deleteURL"
				:delete-note="
					vendor.activated
						? 'To preserve associated records, you could de-activate the Supplier instead.'
						: null
				"
			>
				<template v-if="vendor.activated" v-slot:extra-btn>
					<v-btn
						class="white--text"
						depressed
						color="blue darken-4"
						tile
						v-on:click="
							deleteDialog = false;
							deactivateDialog = true;
						"
					>
						De-activate
					</v-btn>
				</template>
			</DeleteTemplate>
			<DeactivateTemplate
				type="Supplier"
				:deactivate-text="deactivateText"
				v-on:success="goBack()"
				v-on:close="deactivateDialog = false"
				:deactivate-dialog="deactivateDialog"
				:deactivate-url="deactivateURL"
			>
			</DeactivateTemplate>
			<ActivateTemplate
				type="Supplier"
				:activate-text="activateText"
				v-on:success="goBack()"
				v-on:close="activateDialog = false"
				:activate-dialog="activateDialog"
				:activate-url="activateURL"
			>
			</ActivateTemplate>
		</template>
		<template v-else>
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>
	</v-sheet>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
	GetSupplier,
	MarkAsDefaultContactPerson,
	DeleteContactPerson,
} from "@/core/lib/contact.lib";
import ImageTemplate from "@/view/components/Image";
import CreateOrUpdatePerson from "@/view/components/CreateOrUpdatePerson";
import CreateOrUpdateBillingAddress from "@/view/components/CreateOrUpdateBillingAddress";
import TextInputInternal from "@/view/components/TextInputInternal";
import PhoneInputInternal from "@/view/components/PhoneInputInternal";
import Comments from "@/view/components/Comments";
import SendMail from "@/view/components/Send-Mail";
import ServiceListing from "@/view/components/ServiceListing";
import AssetListing from "@/view/components/AssetListing";
import AssetStockListing from "@/view/components/AssetStockListing";
import InventoryListing from "@/view/components/InventoryListing";
import ShowValue from "@/view/components/ShowValue";
import Files from "@/view/components/Files";
import { isEmpty } from "lodash";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ActivateTemplate from "@/view/components/ActivateTemplate";
import SupplierProductListing from "@/view/components/SupplierProductListing";
import DeactivateTemplate from "@/view/components/DeactivateTemplate";
import EventListing from "@/view/components/EventListing";
import SupplierAddressBook from "@/view/components/MemberAddressBook";
import ShowTextAreaValue from "../../../components/ShowTextAreaValue.vue";
import ShowBarcode from "../../../components/ShowBarcode.vue";

export default {
	name: "supplier-detail",
	title: "Detail Supplier",
	data() {
		return {
			panel12: [0, 1],
			uuid: null,
			barcode: null,
			activateText: null,
			activateURL: null,
			deactivateText: null,
			deactivateURL: null,
			deleteText: null,
			deleteURL: null,
			formValid: true,
			deleteDialog: false,
			addressDialog: false,
			addressType: null,
			mail_dialog: false,
			addressModel: new Object({
				id: null,
				uuid: null,
				name: null,
				location: null,
				address_line_1: null,
				address_line_2: null,
				unit_number: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				address_type: 1,
			}),
			personDialog: false,
			personModel: new Object({
				id: null,
				uuid: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				email: null,
				work_phone: null,
				mobile: null,
			}),
			vendor: {
				id: null,
				uuid: null,
				uen: null,
				salutation: "mr",
				first_name: null,
				last_name: null,
				company_name: null,
				display_name: null,
				email: null,
				work_phone: null,
				mobile: null,
				fax: null,
				website: null,
				remark: null,
				image: null,
				billing: {
					id: null,
					uuid: null,
					attention: null,
					country: null,
					street_1: null,
					street_2: null,
					city: null,
					state: null,
					postalcode: null,
					phone: null,
					fax: null,
				},
				shipping: {
					id: null,
					uuid: null,
					attention: null,
					country: null,
					street_1: null,
					street_2: null,
					city: null,
					state: null,
					postalcode: null,
					phone: null,
					fax: null,
				},
			},
			contact_person: [
				{
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: "John",
					last_name: "parker",
					email: "john@bthrust.com",
					work_phone: "6585858586",
					full_name: "John Parker",
					default: false,
					mobile: "5856856585",
				},
				{
					id: null,
					uuid: null,
					salutation: "mr",
					first_name: "John",
					last_name: "parker",
					email: "john@bthrust.com",
					work_phone: "6585858586",
					full_name: "John Parker",
					default: false,
					mobile: "5856856585",
				},
			],
			category: {},
			supplier: {
				company_name: "Bthrust",
				display_name: "Adrian",
				uen: "UEN0000023",
				email: "john@bthrust.com",
				phone: 856325856,
				address: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, dicta!",
			},
			billing_address: {
				id: null,
				uuid: null,
				address_line_1: null,
				address_line_2: null,
				address_postal_code: null,
			},
			deactivateDialog: false,
			activateDialog: false,
			contentLoaded: false,
			pageLoading: false,
		};
	},
	methods: {
		doAction(param) {
			switch (param) {
				case "edit":
					this.$router.push({
						name: "supplier-update",
						params: { uuid: this.uuid },
						query: { t: new Date().getTime() },
					});
					break;
				case "clone":
					this.$router.push({
						name: "supplier-create",
						query: { clone: this.uuid, t: new Date().getTime() },
					});
					break;
				case "delete":
					this.deleteDialog = true;
					break;
				case "deactivate":
					this.deactivateDialog = true;
					break;
				case "activate":
					this.activateDialog = true;
					break;
				case "new-address":
					this.addressDialog = true;
					break;
				case "print-label":
					break;
			}
		},
		addressFormat(address) {
			let formattedAddress = [];
			if (address.address_line_1) {
				formattedAddress.push(address.address_line_1);
			}
			if (address.address_line_2) {
				formattedAddress.push(address.address_line_2);
			}
			if (address.address_state) {
				formattedAddress.push(address.address_state);
			}
			if (address.address_postal_code) {
				formattedAddress.push(address.address_postal_code);
			}
			if (address.address_country) {
				formattedAddress.push(address.address_country);
			}
			return formattedAddress.join(", ");
		},
		getVendor() {
			const _this = this;
			GetSupplier("supplier", _this.uuid)
				.then(({ address, category, customer, contact_person }) => {
					_this.barcode = customer.barcode;
					//_this.vendor = customer;
					_this.supplier = customer;
					_this.billing_address = address[0];
					_this.contact_person = contact_person;
					_this.category = category;
					const adresss = {
						id: address[0].id || null,
						uuid: address[0].uuid || null,
						name: address[0].name || null,
						location: address[0].location || null,
						address_line_1: address[0].address_line_1 || null,
						address_line_2: address[0].address_line_2 || null,
						unit_number: address[0].unit_number || null,
						address_country: address[0].address_country || null,
						address_postal_code: address[0].address_postal_code || null,
						description: address[0].description || null,
						address_type: 1,
					};
					_this.addressModel = { ...adresss };
					this.deleteURL = `contact/${customer.uuid}`;
					this.activateText = `${customer.display_name}`;
					this.activateURL = `contact/supplier/${customer.uuid}/activate`;
					this.deactivateText = `${customer.display_name}`;
					this.deactivateURL = `contact/supplier/${customer.uuid}/de-activate`;

					this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Supplier", disabled: true, href: "/contacts/supplier" },
						// { text: "Detail", disabled: true },
						{ text: _this.barcode, disabled: true, href: "#" },
					]);
				})
				.catch((error) => {
					//this.goBack();
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
					this.contentLoaded = true;
				});
		},
		pageTitle() {
			if (this.uuid) {
				return this.vendor.display_name + " - " + this.vendor.barcode;
			}
			return "Vendor Detail";
		},
		updateAddressDialog(type) {
			this.addressDialog = true;
			this.addressType = type;
			if (type === "billing") {
				this.addressModel = new Object({ ...this.vendor.billing_address });
			} else if (type === "shipping") {
				this.addressModel = new Object({ ...this.vendor.shipping_address });
			}
			console.log(this.vendor.shipping_address);
		},
		addPerson(person) {
			this.personDialog = true;
			this.personModel = new Object();
			if (person && !isEmpty(person)) {
				this.personModel = new Object({
					id: person.id || null,
					uuid: person.uuid || null,
					salutation: person.salutation || null,
					first_name: person.first_name || null,
					last_name: person.last_name || null,
					email: person.email || null,
					mobile: person.phone || null,
					did: person.did || null,
					display_name: person.display_name || null,
					designation: person.designation || null,
					primary: person.primary || null,
				});
			}
		},
		createPerson() {
			this.personDialog = true;
			this.personModel = new Object();
			this.personModel = new Object({
				id: null,
				uuid: null,
				salutation: null,
				first_name: null,
				last_name: null,
				email: null,
				mobile: null,
				did: null,
				display_name: null,
				designation: null,
				primary: null,
			});
		},
		async markAsPrimary(uuid) {
			try {
				await MarkAsDefaultContactPerson(uuid, "supplier/contact");
				this.getVendor();
				this.$store.commit(SET_MESSAGE, [
					{
						model: true,
						message: "Success ! This contact person has been marked as your primary contact person.",
					},
				]);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
		async deletePerson(uuid) {
			try {
				await DeleteContactPerson({ url: "supplier/contact", uuid: uuid });
				this.getVendor();
				this.$store.commit(SET_MESSAGE, [
					{
						model: true,
						message: "Success ! The contact person has been deleted.",
					},
				]);
			} catch (error) {
				this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
			}
		},
	},
	computed: {
		vendorTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.tab = val;
				if (val != this.vendorTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.tab || "contact_person";
			},
		},
	},
	components: {
		ShowValue,
		ImageTemplate,
		TextInputInternal,
		PhoneInputInternal,
		CreateOrUpdatePerson,
		CreateOrUpdateBillingAddress,
		Comments,
		ServiceListing,
		Files,
		SupplierAddressBook,
		AssetListing,
		AssetStockListing,
		SendMail,
		InventoryListing,
		DeleteTemplate,
		EventListing,
		ActivateTemplate,
		DeactivateTemplate,
		ShowTextAreaValue,
		ShowBarcode,
		SupplierProductListing,
	},
	mounted() {
		let query = { ...this.$route.query };
		this.vendorTab = query.tab;
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Supplier", disabled: true },
			{ text: "Detail", disabled: true },
		]);

		const { uuid } = this.$route.params;
		this.uuid = uuid;
		if (!this.uuid) {
			this.goBack();
		}
		this.getVendor();
		this.contentLoaded = true;
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
